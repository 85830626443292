const getVercelEnv = () => {
  const env = process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'development';
  switch (env) {
    case 'production': {
      return 'production';
    }
    case 'preview': {
      return 'preview';
    }
    default: {
      return 'development';
    }
  }
};

// cf. https://vercel.com/docs/projects/environment-variables/system-environment-variables
export const VERCEL_ENV = getVercelEnv();

const isProd = process.env.NODE_ENV === 'production';

export const IS_PRODUCTION = isProd && VERCEL_ENV === 'production';
export const IS_STG = VERCEL_ENV === 'preview';

export const HOSTING_PATH = isProd
  ? process.env.NEXT_PUBLIC_HOSTING_PATH ?? undefined
  : undefined;

export const BASE_PATH = isProd
  ? process.env.NEXT_PUBLIC_BASE_PATH ?? undefined
  : undefined;

// category path
export const CATEGORY_SLUG_PREFIX = 'category_' as const;

// course path
export const COURSE_DIR = 'landing_pages' as const;
export const COURSE_SLUG_PREFIX = 'course_' as const;
export const CATEGORY_PREFIX = 'category_' as const;

// meta
export const SITE_NAME = 'SHElikes（シーライクス）' as const;
export const COPYRIGHT = 'SHE inc. All Rights Reserved.' as const;

export const SITE_TITLE = `${SITE_NAME}無料体験レッスン受付中` as const;
export const SITE_DESCRIPTION =
  'SHEでは、Webデザインやライターなど、女性が&quot;好き&quot;を本気で仕事にするためのレッスンが揃っています。今だけ体験レッスン受講無料キャンペーン実施中。' as const;

// Default Images
const convertPath = (path: string) => {
  if (!BASE_PATH) {
    return path;
  }

  return new URL(path, BASE_PATH).href;
};
export const OG_IMAGE = convertPath('/images/ogp.png');

// Default course thumbnail images
// video cover 480 × 270
export const DEFAULT_COURSE_VIDEO_COVER = convertPath(
  '/images/courses/thumbnails/default-video-cover.webp',
);
// course card image 480 x 240
export const DEFAULT_COURSE_THUMBNAIL = convertPath(
  '/images/courses/thumbnails/now-printing.webp',
);

// Default course pickup card image 144 x 190 px
export const DEFAULT_COURSE_CARD_IMAGE = convertPath(
  '/images/courses/books/now-printing.png',
);

// default instructor avatar
export const DEFAULT_INSTRUCTOR_AVATAR = convertPath(
  '/images/instructor/avatars/no-image.png',
);

// SHE shares WordPress API endpoint
export const SHEsharesAPI = `https://shares.shelikes.jp/wp-json/wp/v2` as const;

// URL
export const SHElikes_TOP = 'https://shelikes.jp/' as const;

export const PAGE_LOGIN = 'https://shelikes.jp/sign_in' as const;

export const PAGE_FAQ =
  'https://support.shelikes.jp/?_gl=1*13fe3ip*_ga*MTQ2NzczNDU1Mi4xNjkxNTU0MTIy*_ga_1GE7V4GNEC*MTY5MjY3NTM3Ny44OS4wLjE2OTI2NzUzNzcuNjAuMC4w*_ga_PDKYP3QVRN*MTY5MjY3NTM3Ny44OS4wLjE2OTI2NzUzNzcuNjAuMC4w&_ga=2.28081402.734920094.1692577469-1467734552.1691554122' as const;

export const TRIAL_LESSON =
  'https://trial-lesson.shelikes.jp/trial_lessons' as const;

// Section ID
export const CAMPAIGN_SECTION_ID = 'cp2' as const;
