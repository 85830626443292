import Script from 'next/script';
import { FC } from 'react';

import { IS_PRODUCTION } from '@/configs';
import { gtmID } from '@/utils/gtm';

// :warning: This Component don't work in `<Head>` component and `_document.tsx`. Generate `script` after adding tags by `<Head>` components
export const GTMScript: FC = () => {
  if (!gtmID) {
    return null;
  }

  const gtmScriptPath = IS_PRODUCTION
    ? 'https://server-side-tagging.shelikes.jp/gtm.js'
    : 'https://www.googletagmanager.com/gtag/js';

  return (
    <Script id='googleTagManager' strategy='beforeInteractive'>
      {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='${gtmScriptPath}?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmID}');`}
    </Script>
  );
};

export const GTMNonScript: FC = () => {
  if (!gtmID) {
    return null;
  }

  const gtmScriptPath = IS_PRODUCTION
    ? `https://server-side-tagging.shelikes.jp/ns.html?id=${gtmID}`
    : `https://www.googletagmanager.com/ns.html?id=${gtmID}`;

  // When `script` is enabled, the content in `noscript` is escaped.
  return (
    <noscript>
      <iframe
        src={gtmScriptPath}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};
