import { Provider } from 'jotai';

import { GTMScript } from '@/components/features/GoogleTagManager';

import type { AppProps } from 'next/app';

import '@acab/reset.css';
import '@/styles/global.css';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <Provider>
      <GTMScript />
      <Component {...pageProps} />
    </Provider>
  );
}
